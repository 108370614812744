/* ----------------------------------------------------------------------------------------------------- */
/*  @ Import/write your custom styles here.
/*  @ Styles from this file will override 'vendors.scss' and Fuse's base styles.
/* ----------------------------------------------------------------------------------------------------- */

.text-7xl {
  div {
    font-size: 2.5rem !important;
  }
}

quick-chat {
  display: none;
}

.rtr-table {
  a {
    color: rgb(55 48 163 / var(--tw-text-opacity)) !important;
  }
}


.filter-box {
  float: right;
  width: 300px;

  .mat-form-field {
    width: 100%;
  }
}


.main-layout {
  float: left;
  width: 100%;
  // padding-bottom: 50px;
}

.for-upload-box {

  position: relative;

  input {
    float: left;
    width: 100%;
    height: 100%;
  }

  .file-btn {
    opacity: 0;
    position: absolute;
    left: 0;
    z-index: 2;
    cursor: pointer;
  }


  button {
    position: absolute;
    right: 0;
    border-radius: 0 !important;
    z-index: 1;
    cursor: pointer;
  }
}

.third-width {
  width: 33%;
}

.company-logo {
  display: flex;
  float: left;
  width: 100%;
  align-items: center;

  p {
    font-size: 16px;
    font-weight: 600;
    color: #000;

  }
}

.light-gray-btn {

  background: rgba(0, 0, 0, 0.1) !important;
}


project {
  .tab-pad-0 {

    .mat-tab-group .mat-tab-header .mat-tab-label-container {
      padding: 0;
    }

    .mat-tab-body-content {
      padding: 0;

      .mat-tab-group {
        padding-top: 20px !important;
      }

    }
  }
}

.mat-table {
  th {
    padding-left: 10px !important;
    &:last-child {
      padding-right: 10px !important;
  }
  }

  td {
    position: relative;
    text-align: left;
    padding-left: 10px !important;
    &:last-child {
      padding-right: 10px !important;
  }
    .action-btn {
      position: absolute;
      right: 13%;
      top: 0;
      height: 100%;
      display: flex;
      align-items: center;



      button {
        padding: 0 !important;
        margin: 0;
        min-width: auto;
        width: 20px;
      }

    }
  }
}

.footer-width-bottom {

  width: calc(100% - 280px);
}

.mat-menu-panel.xs-v {
  min-width: 80px !important;
  min-height: 40px;

  button {
    min-height: 30px;
    height: 30px;
  }
}

.uni-hi {

  .mat-form-field-flex {
    min-height: 40px !important;
  }

}

.btn-eye {

  // background-color: var(--fuse-primary) !important;
  background-color: #53B3F9 !important;
  padding: 0;
  max-width: 40px !important;
  min-width: 40px !important;
  min-height: 40px !important;
  max-height: 40px !important;
  border-radius: 5px !important;

  mat-icon {
    color: #fff;
  }
}

.btn-cancel {

  background-color: transparent;
  padding: 0;
  max-width: 48px !important;
  min-width: 48px !important;
  min-height: 48px !important;
  max-height: 48px !important;
  border-radius: 5px !important;

  mat-icon {
    color: rgba(var(--fuse-accent-700-rgb), var(--tw-bg-opacity)) !important;
  }


}

.footer-width-bottom {

  z-index: 2;
}

.float-left {
  float: left;
}

.float-right {
  float: right;
}


@media only screen and (max-width: 768px) {

  .animate__animated.animate__slideInRight {

    width: 100vw !important;
  }

  .footer-width-bottom {

    width: 100%;
  }

}

.green-snackbar {
  background: rgb(34 197 94 / var(--tw-text-opacity)) !important;
  color: white !important;
}

fuse-vertical-navigation {

  z-index: 100 !important;

}

.cdk-overlay-container {

  z-index: 1003 !important;
}


.red-snackbar {
  background: #F44336 !important;
  color: white !important;
}

input[readOnly] {
  color: rgb(178, 186, 195) !important;
  ;
}

// fuse-vertical-navigation{
//     z-index: 1001!important;
// }

.mat-option {
  position: relative !important
}

.mat-optgroup-label {
  display: none !important;
}

.header {
  background: #111725;
  color: #FFFFFF;
  font-weight: 600;
  font-size: 16px;
}

.mat-header-cell,
.mat-cell {
  min-width: 160px;
  cursor: auto;
  font-size: 14px;
}

.table-box1 {
  overflow-x: auto;

  .mat-icon {
    color: #111725;
  }

  table {
    width: 100%;

    mat-icon {
      font-size: 20px;
    }

    tr {
      border-bottom: 1px solid #e2e8f0;
      height: 40px;

      &:last-child {
        border-bottom: 0;
      }
    }

    th {
      color: #111725 !important;
      font-size: 14px;
      font-weight: 600;
      text-align: left;
      min-height: 48px;
      padding: 0 6px;
    }

    td {
      padding: 0 6px;
      color: #1e293b;
      font-size: 14px;
      min-height: 48px;

      .mat-form-field {
        width: 100%;
        max-width: 100%;
        margin-top: 4px;

        .mat-form-field-wrapper {
          margin-bottom: 0;

          .mat-form-field-flex {
            min-height: 35px;

            input {
              padding: 10px 0 !important;
            }
          }
        }
      }
    }
  }
}

#tabBlock {
  .mat-tab-header .mat-tab-label-container {
    padding: 0;
  }

  .mat-tab-label {
    --tw-bg-opacity: 1 !important;
    min-width: 140px !important;
    background-color: #E9E9E9 !important;
    border-radius: 6px 6px 0 0 !important;
  }

  .mat-tab-label-active {
    background-color: #FFFFFF !important;
  }

  .mat-tab-body {
    background: #FFFFFF;
  }
}

.eventpop {
  .mat-dialog-container {
    padding: 0 !important;
    border-radius: 10px !important;
  }
}

#btnPagination {
  .mat-button-toggle {
    margin: 0;
  }

  .mat-button-toggle-button .mat-icon {
    border: 1px solid #F4F5F9;
  }

  .mat-button-toggle-label-content {
    padding: 0;
  }
}

.ql-container {
  height: auto;
}

/* My Css Start */
.preprtmeplate_list {
  display: flex;
}

/* My Css End */
.mat-expansion-panel-header.mat-expanded {
  border-bottom: 1px solid rgba(var(--fuse-border-rgb), var(--tw-border-opacity));
  height: 45px;
}

.mat-expansion-panel-body {
  padding: 0 !important;
}

.approval_level_option .mat-form-field-wrapper {
  margin-bottom: 0 !important;
}

/* My Css End */
mat-checkbox label,
.mat-checkbox-label,
.mat-radio-label-content,
mat-radio-button label {
  width: 100%;
}

.danger {
  color: red;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

td {
  &.no-td-block {
    max-width: inherit !important;

    .mat-form-field-infix {
      max-width: inherit;
      width: inherit !important;

      .mat-select {
        width: auto !important;
      }
    }

  }
}

.mat-paginator-container {
  padding-right: 0 !important;
}

// .mat-placeholder-required{
//     color: #F44336;
// }
#editor {
  .ql-toolbar {
    background: #EEEEEE;
    padding: 5px !important;
  }

  .ql-formats {
    background: #FFFFFF;
    border: 1px solid #D9D9D9;
    border-radius: 8px;
    padding: 3px;
    margin: 5px !important;
  }
}

#editor.redBorder {
  border: 1px solid #dc2626;
  border-radius: 6px;

  .ql-toolbar.ql-snow,
  .ql-container.ql-snow {
    border: 0;
  }
}

#editor.blueBorder {
  border: 1px solid #1E293B;
  border-radius: 6px;

  .ql-toolbar.ql-snow,
  .ql-container.ql-snow {
    border: 0;
  }
}

.mat-raised-button {
  min-width: 115px !important;
}
.secBtn{
  background: #D9D9D9 !important;
}
// timepicker css
.timepicker-overlay {
  z-index: 9999 !important;
}

.timepicker-backdrop-overlay {
  z-index: 9999 !important;
}

.timepicker {
  border-radius: 4px !important;

  * {
    font-family: "Inter var" !important;
  }

  input[readOnly] {
    color: #fff !important;
  }
}

.timepicker-dial__container {
  justify-content: center !important;
}

.timepicker__header {
  background: #53B3F9 !important;
  border-bottom: 1px solid var(--ion-color-white);
  padding: 5px !important;
  border-radius: 4px 4px 0 0;
}

.clock-face__number>span.active {
  background-color: #53B3F9 !important;
  color: #FFFFFF !important;
}

.clock-face__clock-hand {
  background-color: #53B3F9 !important;

  &:after {
    background-color: #53B3F9 !important;
    left: -2.5px !important;
  }
}

.clock-face {
  width: 265px !important;
  height: 265px !important;
  padding: 10px !important;
  box-shadow: 0 1px 5px #9f9c9c;
  background-color: #f4f3f3 !important;
}

.clock-face__clock-hand_minute:before {
  border: 4px solid #53B3F9 !important;
}

.timepicker__actions {
  justify-content: space-between !important;
  padding: 15px 20px 20px !important;
  border-radius: 0 0 4px 4px;

  .timepicker-button {
    background: #53B3F9 !important;
    color: #FFFFFF !important;
    line-height: 30px !important;
    border-radius: 4px !important;

    span {
      font-weight: 600;
    }
  }

  div:first-child .timepicker-button {
    background: #FFFFFF !important;
    color: #53B3F9 !important;
    border: 2px solid #53B3F9;
  }
}

.clock-face__number--outer>span {
  color: #000 !important;
  width: 35px !important;
  height: 35px !important;
}

::placeholder {
  font-size: 13px;
}
body.light .mat-raised-button.mat-button-disabled.mat-button-disabled{
  background-color: rgba(148, 163, 184, 0.38) !important;
}
hr {
  margin: 15px 0;
  border-top: 0;
}
span.text-primary,.hover\:text-primary:hover,i.text-primary,a.text-primary{
  color: rgb(59 130 246 / var(--tw-text-opacity)) !important;
  cursor: pointer;
}

.flex-column{

  flex-flow: column;
}
@media only screen and (max-width: 767px) {
  .text-2xl{
      margin-bottom: 15px;
  }
  .col1, .col2{
      position: static !important;
  }
}